// export const imgUrl = 'https://bishworang.website/admin/application/public/assets/images/setting/'
// export const catImg = 'https://bishworang.website/admin/application/public/assets/images/category/'
// export const sliderImg = 'https://bishworang.website/admin/application/public/assets/images/slider/'
// export const productImg = 'https://bishworang.website/admin/application/public/assets/images/product/'
// export const bannerImg = 'https://bishworang.website/admin/application/public/assets/images/banner/'
// export const testimonialImg = 'https://bishworang.website/admin/application/public/assets/images/testimonials/'
// export const profileImg = 'https://bishworang.website/admin/application/public/assets/images/img/'
// export const campaignImg = 'https://bishworang.website/admin/application/public/assets/images/campaign/'
// export const blogImg = 'https://bishworang.website/admin/application/public/assets/images/blog/'
// export const offerImg = 'https://bishworang.website/admin/application/public/assets/images/offer/'



export const imgUrl = 'https://bishworang.com.bd/admin/assets/images/setting/'
export const catImg = 'https://bishworang.com.bd/admin/assets/images/category/'
export const sliderImg = 'https://bishworang.com.bd/admin/assets/images/slider/'
export const productImg = 'https://bishworang.com.bd/admin/assets/images/product/'
export const bannerImg = 'https://bishworang.com.bd/admin/assets/images/banner/'
export const seasonImg = 'https://bishworang.com.bd/admin/assets/images/season/'
export const testimonialImg = 'https://bishworang.com.bd/admin/assets/images/testimonials/'
export const profileImg = 'https://bishworang.com.bd/admin/assets/images/img/'
export const campaignImg = 'https://bishworang.com.bd/admin/assets/images/campaign/'
export const blogImg = 'https://bishworang.com.bd/admin/assets/images/blog/'
export const offerImg = 'https://bishworang.com.bd/admin/assets/images/offer/'
