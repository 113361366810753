
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { token } from "../services/AxiosInstance";
// import { toast } from 'react-toastify'


import httpReq from "../services/http.service";

export const v = JSON.parse(localStorage.getItem("persist:root"))?.auth ? JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.auth)?.user : null

// const baseUrl = "https://bishworang.com.bd/admin/api/v1/"

const useData = () => {
    const [userData, setUser] = useState(null)
    const [favourite, setFavourite] = useState([])
    const [page, setPage] = useState("")

    const [currency, setCurrency] = useState('BDT')
    const [currencyInfo, setcurrencyInfo] = useState({})
    const url = window.location.href


    const [products, setProducts] = useState([]);
    const [popularProduct, setPopularProduct] = useState([]);
    const [featureProduct, setFeatureProduct] = useState([]);
    const [season, setSeason] = useState([]);
    const [homeDecore, setHomeDecore] = useState([]);

    // console.log(products, "products");
    // console.log(popularProduct, "popularProduct");
    // console.log(season, "season");

    useEffect(() => {
        httpReq.get(`products`).then((response) => {
            setProducts(response?.results);
        });
        httpReq.get(`popular_product`).then((response) => {
            setPopularProduct(response);
        });
        httpReq.get(`feature_product`).then((response) => {
            setFeatureProduct(response);
        });
        httpReq.get(`season`).then((response) => {
            setSeason(response);
        });
        httpReq.get(`home_decore`).then((response) => {
            setHomeDecore(response);
        });
    }, []);

    const fetchHeader = useCallback(
        async () => {

            const { data } = await axios.get('https://v6.exchangerate-api.com/v6/db25471ea0108ac45b9cfdc4/latest/USD')
            setcurrencyInfo(data)

            if (token && v?.verify) {
                const user = await httpReq.get('getuser');
                const { favourite } = await httpReq.get('favourite/get');
                setUser(user)
                setFavourite(favourite)
            }
        }, [])

    useEffect(() => {
        // declare the async data fetching function

        // call the function
        fetchHeader()

        // Visiter Counter 

        axios.get("https://geolocation-db.com/json/").then(({ data }) => {
            // console.log(data,"data");
            if (data?.IPv4) {
                httpReq.post('visitor', { ip: data?.IPv4, url: url, mac: '' })
                    .then(({ success }) => {
                        // toast(success, { type: 'success' })
                        // console.log(success,"hfghh");
                    })
            }
        })


            // make sure to catch any error
            .catch(console.error);;
    }, [fetchHeader, url])


    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }





    return {
        makeid,
        userData,
        favourite,
        currency,
        setCurrency,
        currencyInfo,
        page,
        setPage,
        products,
        popularProduct,
        featureProduct,
        season,
        homeDecore

    }

}
export default useData