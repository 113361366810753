const firebaseConfig = {
  apiKey: "AIzaSyArPvgsZj8-F0Jjeob9RZbG7GuTiZyWYc4",
  authDomain: "bishowrong-cf403.firebaseapp.com",
  projectId: "bishowrong-cf403",
  storageBucket: "bishowrong-cf403.appspot.com",
  messagingSenderId: "881599986876",
  appId: "1:881599986876:web:3aa80fa9d1fc033c1464b9",
  measurementId: "G-260Q4F8EGE",
};

export default firebaseConfig;
