// import { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faComments,
//   faTimes,
//   faPhoneAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import {
//   faTelegramPlane,
//   faWhatsapp,
//   faFacebookMessenger,
// } from "@fortawesome/free-brands-svg-icons";

// const FloatingButtons = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className=" bg-gray-900 flex items-end justify-end">
//       <div className="fixed right-5 bottom-5 z-10">
//         <div
//           id="main-button"
//           className={`flex items-center justify-center h-12 w-12 text-blue-600 bg-white rounded-full shadow-md cursor-pointer ${
//             isOpen ? "animate-none" : "wave"
//           }`}
//           onClick={toggleOpen}
//         >
//           <FontAwesomeIcon icon={isOpen ? faTimes : faComments} />
//         </div>

//         {/* Free Consultation Button */}
//         <button
//           className={`absolute z-0 h-12 px-5 font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 rounded-md shadow-md whitespace-nowrap transition-all duration-300 top-0 ${
//             isOpen ? "opacity-100 visible right-20" : "opacity-0 invisible"
//           }`}
//         >
//           <FontAwesomeIcon icon={faPhoneAlt} /> Free Consultation
//         </button>

//         {/* WhatsApp */}
//         <a
//           href="#"
//           className={`whatsapp-color flex items-center justify-center h-12 w-12 text-white bg-gradient-to-t from-[#00B100] to-[#09db09] rounded-full shadow-md transition-all duration-400 ${
//             isOpen
//               ? "opacity-100 visible bottom-16"
//               : "opacity-0 invisible bottom-0"
//           } absolute right-0`}
//         >
//           <FontAwesomeIcon icon={faWhatsapp} />
//         </a>

//         {/* Messenger */}
//         {/* <a
//           href="#"
//           className={`messenger-color flex items-center justify-center h-12 w-12 text-white bg-gradient-to-t from-[#0078FF] to-[#00C6FF] rounded-full shadow-md transition-all duration-400 ${
//             isOpen
//               ? "opacity-100 visible bottom-36"
//               : "opacity-0 invisible bottom-0"
//           } absolute right-0`}
//         >
//           <FontAwesomeIcon icon={faFacebookMessenger} />
//         </a> */}
//       </div>
//     </div>
//   );
// };

// export default FloatingButtons;

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faTimes,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTelegramPlane,
  faWhatsapp,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";

const FloatingButtons = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-gray-900 flex items-end justify-end">
      <div className="fixed right-5 bottom-5 z-10">
        <div
          id="main-button"
          className={`flex items-center justify-center h-12 w-12 text-blue-600 bg-white rounded-full shadow-md cursor-pointer transition-transform ${
            isOpen ? "rotate-45" : ""
          }`}
          onClick={toggleOpen}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faComments} />
        </div>

        {/* Free Consultation Button */}
        <a
          href="tel:+1234567890" // Replace with the phone number you want to call
          className={`absolute h-12 px-5 font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 rounded-md shadow-md whitespace-nowrap transition-all duration-300 top-0 z-50 ${
            isOpen
              ? "opacity-100 right-20 transition-slide-in"
              : "opacity-0 right-5 transition-slide-out"
          } flex items-center justify-center`}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
        >
          <FontAwesomeIcon icon={faPhoneAlt} rotation={90} />{" "}
          <span className="ml-2">Free Consultation</span>
        </a>

        {/* WhatsApp */}
        <a
          href="https://wa.me/1234567890"
          className={`whatsapp-color flex items-center justify-center h-12 w-12 text-white bg-gradient-to-t from-[#00B100] to-[#09db09] rounded-full shadow-md transition-all duration-400 z-10 ${
            isOpen
              ? "opacity-100 bottom-16 transition-slide-in"
              : "opacity-0 right-5 transition-slide-out "
          } absolute right-0`}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </div>
  );
};

export default FloatingButtons;
