import React, { Suspense, useState, useEffect } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import Footer from './common/Footer';
// import Main from './common/Main';
import background from "../../assets/images/bg.jpg";
// import Header from './common/Header';
import HeaderSticky from "../components/common/HeaderSticky";
import { ToastContainer } from "react-toastify";
import { bg_gary } from "../../siteSetting/theme";
// import { HomePage } from '../../services';
import axios from "axios";
import FloatingButtons from "../components/BubbleButton";

const Header = React.lazy(() => import("./common/Header"));
const Main = React.lazy(() => import("./common/Main"));
const Footer = React.lazy(() => import("./common/Footer"));
// const baseURL = 'https://bishworang.website/admin/api/v1/allfrontendcontent'
const baseURL = "https://bishworang.com.bd/admin/api/v1/allfrontendcontent";

const Layout = () => {
  // const { isLoading } = HomePage.GetInfo()
  // if (isLoading) {
  //     return <div className="flex justify-center h-screen items-center">
  //         <button className="btn loading">loading</button>
  //     </div>
  // }
  const [post, setPost] = useState(null);
  useEffect(() => {
    axios.get(`${baseURL}`).then((response) => {
      setPost(response?.data);
    });
  }, []);
  if (!post) {
    return (
      <div className="flex justify-center h-screen items-center">
        <button className="btn loading">loading</button>
      </div>
    );
  }

  return (
    <>
      <ToastContainer position="top-right" newestOnTop />
      <div
        className=""
        style={{
          background: `url(${background}) repeat fixed center`,
          backgroundSize: "480px 270px",
          backgroundColor: bg_gary,
          backgroundBlendMode: "luminosity",
          position: "relative",
        }}
      >
        <Suspense>
          <header>
            <Header data={post} />
          </header>
          <HeaderSticky data={post} />
          <main>
            <Main data={post} />
          </main>
          <div>
            <Footer data={post} />
            <FloatingButtons />
          </div>
        </Suspense>
      </div>
      <div id="fb-root"></div>
      <MessengerCustomerChat
        pageId="107637905385179"
        appId="852617662106865"
        htmlRef="fb-root"
      />
    </>
  );
};

export default Layout;
